window.addEventListener('DOMContentLoaded', () => {
    const lang = document.querySelector("html").getAttribute("lang");
    const langBtn = document.querySelector("#lang_btn");
    const langForm = document.querySelector("#lang form");
    const openMenu = document.querySelectorAll(".menu-btn");
    const menu = document.querySelector("#nav_menu");

    if (lang === "hy") {
        document.body.style.cssText = `
            font-family: monregular;
            font-weight: 500;
        `;
    } else {
        document.body.style.cssText = `
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
        `;
    }

    // sliders
    try {
        window.addEventListener("load", () => {
            const swiper = new Swiper(".sl", {
                loop: true,
                spaceBetween: 10,
                slidesPerView: 4,
                freeMode: true,
                watchSlidesProgress: true
            });
            const swiper2 = new Swiper(".sl2", {
                loop: true,
                spaceBetween: 10,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                thumbs: {
                    swiper: swiper,
                },
            });
            const swiper3 = new Swiper(".sl3", {
                loop: true,
                direction: "vertical",
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            });
            const swiper4 = new Swiper(".sl4", {
                slidesPerView: 3,
                spaceBetween: 10,
                loop: true,
                breakpoints: {
                    560: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    960: {
                        slidesPerView: 3,
                    },
                    1120: {
                        slidesPerView: 4,
                    },
                    1250: {
                        slidesPerView: 2,
                    }
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }
            });
        });

    } catch {}

    // product page count
    try {
        const countInput = document.querySelector("#counter");
        const plus = document.querySelector("#plus");
        const minus = document.querySelector("#minus");
        const itemPrice = document.querySelector("#current > b");
        const innerTotal = document.querySelector("#total > b");
        const toBasket = document.querySelector("#toBasket");

        let i = 0;
        let total = 0;

        countInput.value = i;

        countInput.addEventListener("input", (e) => {
            let value = e.target.value;
            let resOnInput = 0;
            i = parseFloat(value);

            const reg = new RegExp('^[0-9]+$');

            if (!reg.test(value)) {
                setTimeout(() => {
                    countInput.value = "";
                    innerTotal.textContent = 0;
                }, 10);
            }

            if (value !== "" && value !== 0 && !isNaN(value)) {
                resOnInput += parseFloat(itemPrice.textContent);
                total = parseFloat(resOnInput) * parseFloat(value);
                innerTotal.textContent = total;
            }
        });

        plus.addEventListener("click", () => {
            if (countInput.value !== 0 && countInput.value !== "" && countInput.value !== "NaN") {
                countInput.value = ++i;
                total += parseFloat(itemPrice.textContent);
                innerTotal.textContent = total;
            }
        });

        minus.addEventListener("click", () => {
            if (countInput.value != 0 && countInput.value !== "" && countInput.value !== "NaN") {
                countInput.value = --i;
                total -= parseFloat(itemPrice.textContent);
                innerTotal.textContent = total;
            }
        });

        toBasket.addEventListener("click", () => {
            const val = parseInt(innerTotal.textContent);

            if (val <= 0) {
                toBasket.classList.add("error");
            } else {
                toBasket.classList.remove("error");
            }
        });
    } catch {}

    //basket page
    try {
        const remove = document.querySelectorAll(".remove_item");
        const plus = document.querySelectorAll("[data-plus]");
        const minus = document.querySelectorAll("[data-minus]");
        const input = document.querySelectorAll("[data-count]");
        const bidTotal = document.querySelectorAll("[data-bid-total]");
        const bidPrice = document.querySelectorAll("[data-bid-price]");
        const totalCost = document.querySelector("#total_cost");
        
        // start value of inputs (count)
        input.forEach(input => input.value = input.dataset.count);

        // remove function
        remove.forEach(btn => {
            btn.addEventListener("click", (e) => {
                const parent = e.target.parentElement.parentElement.parentElement;
                const total = parent.querySelector("[data-bid-total]").textContent;
                totalCost.textContent -= total;
                parent.remove();
            });
        });

        // on + or - update function to total
        const update = () => {
            const a = Array.from(bidTotal).reduce((bank, current) => {
                const a = bank += parseFloat(current.textContent);
                console.log(a);
                return a;
            }, 0);

            totalCost.textContent = parseFloat(a);
        };

        // counter logic
        const counter = (btnsArr, inp, priceArr, totalArr, operation) => {
            btnsArr.forEach((btn, btnIndex) => {
                btn.addEventListener("click", () => {
                    inp.forEach((val, inputIndex) => {
                        if (btnIndex === inputIndex) {
                            if (val.value >= 0) {
                                let count = parseFloat(val.value);
                                let op = operation(count);
                                val.value = op;
    
                                priceArr.forEach((price, priceIndex) => {
                                    if (priceIndex === btnIndex) {
                                        totalArr.forEach((total, totalIndex) => {
                                            if (priceIndex === totalIndex) {
                                                let sum = parseFloat(op) * parseFloat(price.textContent);
                                                total.textContent = parseFloat(sum);
                                                update();
                                            }
                                        });
                                    }
                                });
                            }
                        }
                    });
                });
            });
        };

        counter(plus, input, bidPrice, bidTotal, function (num) {
            return ++num;
        });
        
        counter(minus, input, bidPrice, bidTotal, function (num) {
            if (num > 1) {
                return --num;
            }
            num = 1;
            return num;
        });
    } catch {}

    openMenu.forEach(item => {
        item.addEventListener("click", () => {
            item.classList.toggle("menu-btn_active");

            if (item.classList.contains("menu-btn_active")) {
                menu.classList.add("active_nav_menu");
            } else {
                menu.classList.remove("active_nav_menu");
            }
        });
    });

    langBtn.addEventListener("click", () => {
        if (!langForm.classList.contains("active_form")) {
            langForm.classList.add("active_form");
        } else {
            langForm.removeAttribute("class");
        }
    });

    langForm.addEventListener("mouseleave", (e) => {
        e.target.removeAttribute("class");
    });

    try {
        const label = document.querySelectorAll(".label");

        label.forEach(btn => {
            btn.addEventListener("click", () => {
                label.forEach(btn => {
                    btn.classList.remove("active_label");
                });

                if (!btn.classList.contains("active_label")) {
                    btn.classList.add("active_label");
                } else {
                    btn.classList.remove("class");
                }
            });
        });
    } catch {}

});